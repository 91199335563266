import logo from './logo.svg';
import './App.scss';


import Layout from './layouts/Layout';

function App() {

  return (
    <Layout/>
  );
}

export default App;
