import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';


const StepOne = (props) => {

  const [ student, updateStudent ] = useState({});
  const [ school, updateSchool ] = useState({});

  const countries = props.countries;

  useEffect(() => {

    if(localStorage.getItem('student')){
      updateStudent(JSON.parse(localStorage.getItem('student')));
    }

    if(localStorage.getItem('previous_school')){
      updateSchool(JSON.parse(localStorage.getItem('previous_school')));
    }

  }, [])

  useEffect(() => {


    console.log('SCHOOL', school);
    if(school){
      localStorage.setItem('previous_school', JSON.stringify(school))
    }

  }, [school])

  useEffect(() => {

    console.log('STUDENT', student);
    if(student){
      localStorage.setItem('student', JSON.stringify(student))
    }

  }, [student])

  const handleSchoolChange = (e) => {
    updateSchool({
      ...school,
      [e.target.name]: e.target.value
    });
  };

  const handleChange = async (e) => {

    if(e.target.type === 'file'){

      let file = e.target.files[0];

      updateStudent({
        ...student,
        [e.target.name]: file
      });

    } else {

      updateStudent({
        ...student,
        [e.target.name]: e.target.value
      });

    }

  };

  const handleSubmit = (e) => {
    e.preventDefault()
    props.updateStep('inc');
  }

  if(student){

    return(
      <div>
       <form onSubmit={handleSubmit}>
          <h2>Student informations</h2>

          <div className="form-group">
            <label for="name">Nom Prénom</label>
            <input onChange={handleChange} placeholder="John Doe" type="text" className="form-control" id="name" name="name" value={student.name} required/>
            <small className="student-text text-muted">Name Surname</small>
          </div>

          <div className="form-group">
            <label for="birthday">Date de naissance</label>
            <input onChange={handleChange} type="date" className="form-control" id="birthday" name="birthday" value={student.birthday} required/>
            <small className="student-text text-muted">Birth date</small>
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text" for="gender">Genre</label>
            </div>
            <select onChange={handleChange} className="custom-select" id="gender" name="gender" defaultValue={student.gender}>
              <option selected>Choose...</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <small className="student-text text-muted mb-3">Gender</small>


          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text" for="birth_country">Lieu de naissance</label>
            </div>
            <select onChange={handleChange} className="custom-select" id="birth_country" name="birth_country" defaultValue={student.birth_country}>
              <option selected>Choose country of birth...</option>
              {countries &&
                countries.length > 0 &&
                countries.map((item,key) => <option value={item.id}>{item.name}</option>)
              }
            </select>
          </div>
          <small className="student-text text-muted">Country of birth</small>



          <div className="form-group">
            <label for="nationality">Nationalité</label>
            <input onChange={handleChange} placeholder="French" type="text" className="form-control" id="nationality" name="nationality" value={student.nationality} required/>
            <small className="student-text text-muted">Nationality</small>
          </div>

          <div className="row">

            <div className="form-group col">
              <label for="first_language">Première langue</label>
              <input onChange={handleChange} placeholder="French" type="text" className="form-control" id="first_language" name="first_language" value={student.first_language} required/>
              <small className="student-text text-muted">First language</small>
            </div>

            <div className="form-group col">
              <label for="second_language">Deuxième langue</label>
              <input onChange={handleChange} placeholder="Vietnamese" type="text" className="form-control" id="second_language" name="second_language" value={student.second_language} />
              <small className="student-text text-muted">Second language</small>
            </div>

          </div>

          <h6 className="mt-4">Ecole précédente / Previous school</h6>
          <div className="row previous-school">
          <div className="col-md-4 col-lg-2">
            <div className="form-group">
              <label for="year">Année</label>
              <input onChange={handleSchoolChange} type="number" min={2000} className="form-control" id="year" name="year" value={school.year} />
              <small className="student-text text-muted">Year</small>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="form-group">
              <label for="school">Ecole</label>
              <input onChange={handleSchoolChange} type="text" className="form-control" id="school" name="school" value={school.school} />
              <small className="student-text text-muted">School</small>
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="form-group">
              <label for="grade">Classe</label>
              <input onChange={handleSchoolChange} type="text" className="form-control" id="grade" name="grade" value={school.grade} />
              <small className="student-text text-muted">Grade</small>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="form-group">
              <label for="city">Ville</label>
              <input onChange={handleSchoolChange} type="text" className="form-control" id="city" name="city" value={school.city} />
              <small className="student-text text-muted">City</small>
            </div>
          </div>
          <div className="col-md-6 col-lg-2">
            <div className="form-group">
              <label for="country">Pays</label>
              <input onChange={handleSchoolChange} type="text" className="form-control" id="country" name="country" value={school.country} />
              <small className="student-text text-muted">Country</small>
            </div>
          </div>
          </div>

        <div className="my-3">
          <button type="submit" className="btn btn-primary">Next</button>
        </div>

       </form>

       <ProgressBar value={25} />

    </div>
    );



  }


};

 export default StepOne;
