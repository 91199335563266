import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';
import { Form } from 'react-bootstrap';

const StepRelatives = (props) => {

  const [ relatives, updateRelatives ] = useState({});

  useEffect(() => {
    if(localStorage.getItem('relatives')){
      updateRelatives(JSON.parse(localStorage.getItem('relatives')));
    }
  }, [])

  useEffect(() => {
    console.log('RELATIVES', relatives);
    if(relatives){
      localStorage.setItem('relatives', JSON.stringify(relatives))
    }
  }, [relatives])

  const handleChange = (e) => {
    updateRelatives({
      ...relatives,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    props.updateStep('inc');
  }

  if(relatives){

    return(
      <div>
       <form onSubmit={handleSubmit}>
          <h2>Relatives</h2>

          <h3>Father / Père</h3>
          <div className="form-group">
            <label for="father_name">Nom Prénom</label>
            <input onChange={handleChange} placeholder="John Doe" type="text" className="form-control" id="father_name" name="father_name" value={relatives.father_name}/>
            <small className="form-text text-muted">Name Surname</small>
          </div>

          <div className="row">

          <div className="col-md-6">
            <div className="form-group">
              <label for="father_nationality">Nationalité</label>
              <input onChange={handleChange} type="text" className="form-control" id="father_nationality" name="father_nationality" value={relatives.father_nationality}/>
              <small className="form-text text-muted">Nationality</small>
            </div>
            <div className="form-group">
              <label for="father_profession">Profession</label>
              <input onChange={handleChange} type="text" className="form-control" id="father_profession" name="father_profession" value={relatives.father_profession}/>
              <small className="form-text text-muted">Work</small>
            </div>

          </div>
          <div className="col-md-6">

            <div className="form-group">
              <label for="father_email">Email</label>
              <input onChange={handleChange} type="email" className="form-control" id="father_email" name="father_email" value={relatives.father_email}/>
              <small className="form-text text-muted">Email</small>
            </div>
            <div className="form-group">
              <label for="father_phone">Numéro de portable</label>
              <input onChange={handleChange} type="text" className="form-control" id="father_phone" name="father_phone" value={relatives.father_phone}/>
              <small className="form-text text-muted">Mobile number</small>
            </div>
          </div>
          </div>


          <h3>Mother / Mère</h3>
          <div className="form-group">
            <label for="mother_name">Nom Prénom</label>
            <input onChange={handleChange} placeholder="Maria Doe" type="text" className="form-control" id="mother_name" name="mother_name" value={relatives.mother_name}/>
            <small className="form-text text-muted">Name Surname</small>
          </div>

          <div className="row">

          <div className="col-md-6">
            <div className="form-group">
              <label for="mother_nationality">Nationalité</label>
              <input onChange={handleChange} type="text" className="form-control" id="mother_nationality" name="mother_nationality" value={relatives.mother_nationality}/>
              <small className="form-text text-muted">Nationality</small>
            </div>
            <div className="form-group">
              <label for="mother_profession">Profession</label>
              <input onChange={handleChange} type="text" className="form-control" id="mother_profession" name="mother_profession" value={relatives.mother_profession}/>
              <small className="form-text text-muted">Work</small>
            </div>

          </div>

          <div className="col-md-6">

            <div className="form-group">
              <label for="mother_email">Email</label>
              <input onChange={handleChange} type="email" className="form-control" id="mother_email" name="mother_email" value={relatives.mother_email}/>
              <small className="form-text text-muted">Email</small>
            </div>
            <div className="form-group">
              <label for="mother_phone">Numéro de portable</label>
              <input onChange={handleChange} type="text" className="form-control" id="mother_phone" name="mother_phone" value={relatives.mother_phone}/>
              <small className="form-text text-muted">Mobile number</small>
            </div>
          </div>
          </div>

          <h3>Situation familial / Family situation</h3>
          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text" for="family_situation">Situation</label>
            </div>
            <select onChange={handleChange} className="custom-select" id="family_situation" name="family_situation" defaultValue={relatives.family_situation}>
              <option selected>Choose...</option>
              <option value="married">Marié / Married</option>
              <option value="divorced">Divorcé / Divorced</option>
              <option value="widowed">Veuf(ve) / Widowed</option>
              <option value="single">Célibataire / Single</option>
              <option value="marital-life">Vie maritale / Marital life</option>

            </select>
          </div>


          <h3>Personnes authorisées à venir chercher l'enfant</h3>
          <h3 className="text-muted">Authorized people to pick-up the child</h3>

          <div className="row">

          <div className="col-md-6">

            <div className="form-group">
              <label for="r1_name">Nom Prénom</label>
              <input onChange={handleChange} placeholder="John Doe" type="text" className="form-control" id="r1_name" name="r1_name" value={relatives.r1_name}/>
              <small className="form-text text-muted">Name Surname</small>
            </div>

          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label for="r1_link">Lien avec l'enfant</label>
              <input onChange={handleChange} placeholder="nanny, sister..." type="text" className="form-control" id="r1_link" name="r1_link" value={relatives.r1_link}/>
              <small className="form-text text-muted">Link with child</small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="r1_nationality">Nationalité</label>
              <input onChange={handleChange} type="text" className="form-control" id="r1_nationality" name="r1_nationality" value={relatives.r1_nationality}/>
              <small className="form-text text-muted">Nationality</small>
            </div>
          </div>


          <div className="col-md-6">
            <div className="form-group">
              <label for="r1_phone">Numéro de portable</label>
              <input onChange={handleChange} type="text" className="form-control" id="r1_phone" name="r1_phone" value={relatives.r1_phone}/>
              <small className="form-text text-muted">Mobile number</small>
            </div>
          </div>

          </div>




          <div className="my-3">
            <button className="btn btn-secondary mr-1" onClick={() => props.updateStep('dec')}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>

      </form>

       <ProgressBar value={props.progress || 0} />

    </div>
    );



  }


};

 export default StepRelatives;
