import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';
import { Form } from 'react-bootstrap';

const StepMedical = (props) => {

  const [ medical, updateMedical ] = useState({});
  const hospitals = props.hospitals;

  useEffect(() => {
    if(localStorage.getItem('medical')){
      updateMedical(JSON.parse(localStorage.getItem('medical')));
    } else {
      updateMedical({
        sport: true
      })
    }
  }, [])

  useEffect(() => {

    console.log('MEDICAL', medical);
    if(medical){
      localStorage.setItem('medical', JSON.stringify(medical))
    }

  }, [medical])


  const handleCheckBox = (e) => {

    updateMedical({
      ...medical,
      [e.target.name]: e.target.checked ? true : false
    });

  };

  const handleChange = (e) => {
    updateMedical({
      ...medical,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    props.updateStep('inc');
  }

  if(medical){

    return(
      <div>
       <form onSubmit={handleSubmit}>
          <h2>Medical informations</h2>

          <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label for="height">Taille (cm)</label>
              <input onChange={handleChange} type="number" min={50} max={200} className="form-control" id="height" name="height" value={medical.height} />
              <small className="form-text text-muted">Height (cm)</small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="weight">Poids (kg)</label>
              <input onChange={handleChange} type="number" min={10} className="form-control" id="weight" name="weight" value={medical.weight} />
              <small className="form-text text-muted">Weight (kg)</small>
            </div>
          </div>
          </div>

          <div className="form-group">
          <Form.Check
            type="switch"
            id="special_medication"
            name="special_medication"
            label="Under Special Medication"
            onChange={handleCheckBox}
            checked={medical.special_medication || false}
          />

          {medical.special_medication === true &&
            <div>
            <textarea
              placeholder="Indicate medications if any. Indiquez le traitement."
              onChange={handleChange}
              className="form-control"
              id="special_medication_comment"
              name="special_medication_comment"
              value={medical.special_medication_comment}/>
            </div>
          }

          </div>

          <div className="form-group">

          <Form.Check
            type="switch"
            id="glass"
            name="glass"
            label="Port de lunettes / Wear glasses"
            onChange={handleCheckBox}
            checked={medical.glass || false}
          />
          </div>

          <div className="form-group">
            <Form.Check
              type="switch"
              id="sport_practice"
              name="sport_practice"
              label="Pratique le sport à l'école / Sport practice at school"
              onChange={handleCheckBox}
              checked={medical.sport_practice || false}
            />
          </div>

          {medical.sport === false &&
          <div className="form-group">
            <label for="sport_practice_comment">If no sport, why?</label>
            <textarea
              onChange={handleChange}
              className="form-control"
              id="sport_practice_comment"
              name="sport_practice_comment"
              value={medical.sport_practice_comment}/>
            <small className="form-text text-muted">If no sport, why?</small>
          </div>
          }

          <div className="form-group">
            <label for="allergies">Allergies connues</label>
            <textarea onChange={handleChange} min={10} className="form-control" id="allergies" name="allergies" value={medical.allergies}/>
            <small className="form-text text-muted">Known allergies</small>
          </div>


          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text" for="hospital_id">Hôpital</label>
            </div>
            <select onChange={handleChange} className="custom-select" id="hospital_id" name="hospital_id" defaultValue={medical.hospital_id}>
              <option selected>Choose...</option>
              {hospitals &&
                hospitals.length > 0 &&
                hospitals.map((item,key) => <option value={item.id}>{item.name}</option>)}
            </select>
          </div>
          <small className="form-text text-muted mb-3">Emergency hostipal</small>

          <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label for="emergency_doctor">Médecin traitant</label>
              <input onChange={handleChange} type="text" className="form-control" id="emergency_doctor" name="emergency_doctor" value={medical.emergency_doctor} />
              <small className="form-text text-muted">Doctor's name</small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="emergency_phone">Téléphone médecin</label>
              <input onChange={handleChange} type="text" className="form-control" id="emergency_phone" name="emergency_phone" value={medical.emergency_phone} />
              <small className="form-text text-muted">Doctor's phone</small>
            </div>
          </div>
          </div>

        <div className="my-3">
          <button className="btn btn-secondary mr-1" onClick={() => props.updateStep('dec')}>Back</button>
          <button type="submit" className="btn btn-primary">Next</button>
        </div>

       </form>

       <ProgressBar value={props.progress || 0} />

    </div>
    );

  }

};

 export default StepMedical;
