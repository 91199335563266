import React, { useState, useEffect } from 'react';
import logo from '../assets/stange.png';
import logoFooter from '../assets/saintange.png';

import StepOne from '../components/StepOne';
import StepMedical from '../components/StepMedical';
import StepRelatives from '../components/StepRelatives';
import StepOther from '../components/StepOther';

import { baseUrl } from '../constants';

const Layout = () => {

  const [step, setStep] = useState(Number(localStorage.getItem('step')) || 1)
  const [countries, setCountries ] = useState({});
  const [hospitals, setHospitals ] = useState({});

  const updateStep = (mode) => {

    if(mode === 'inc'){
      setStep(step+1)
    } else {
      setStep(step-1)
    }
  }

  const getHospitals = async () => {

    fetch(baseUrl+"/webform/hospitals")
      .then(response => response.json())
      .then(data => {

        if(data.hospitals){
          setHospitals(data.hospitals);
          console.log('HOSPITALS', data.hospitals);
        } else {
          console.error('Hospitals get error');
        }

      });

  }

  const getCountries = async () => {

    fetch(baseUrl+"/countries")
      .then(response => response.json())
      .then(data => {

        if(data.countries){
          setCountries(data.countries);
          console.log('COUNTRIES', data.countries);
        } else {
          console.error('Countries get error');
        }

      });

  }

  useEffect(() => {

    getCountries();
    getHospitals();


  },[]);

  useEffect(() => {

    if(step >= 5){ setStep(1) }
    localStorage.setItem('step', step);
    //ScrollToTop
    window.scrollTo({
       top: 0,
       behavior: "smooth"
    });

  }, [step])

  return (
    <div className="wrapper pt-5 px-0 bg-light">

    <div className="row justify-content-center">

      <div className="col-lg-6 col-md-10 bg-white rounded px-4 shadow">

      <div className="text-center">
        <img src={logo} style={{maxHeight: '150px'}} className="pb-3"/>
      </div>

      <h1 className="pb-5 text-center">Student registration</h1>

      <div>
        {(!step || step === 1) && <StepOne updateStep={updateStep} progress={25} countries={countries} />}
        {step === 2 && <StepRelatives updateStep={updateStep} progress={50}/>}
        {step === 3 && <StepMedical updateStep={updateStep} progress={75} hospitals={hospitals}/>}
        {step === 4 && <StepOther updateStep={updateStep} progress={95}/>}

      </div>

      </div>
    </div>

    <div className="footer text-center mt-5 py-2">
      <div className="text-center">
        <img src={logoFooter} style={{maxWidth: '90%'}} className="py-3"/>
      </div>
      <p className="text-small text-muted">Copyright @2021 <a target="_blank" href="http://sa-saigon.com">Saint Ange</a> | Powered by <a target="_blank" href="https://acte.ltd">ACTE Technology</a></p>
    </div>
    </div>
  );
}

export default Layout;
