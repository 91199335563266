import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';
import { Form } from 'react-bootstrap';
import { baseUrl } from '../constants';
import axios from 'axios';
import { toBase64 } from '../utils';

const StepOther = (props) => {

  const [ other, updateOther ] = useState({});
  const [ files, updateFiles ] = useState({});
  const updateStep = props.updateStep;

  useEffect(() => {
    if(localStorage.getItem('other')){
      updateOther(JSON.parse(localStorage.getItem('other')));
    } else{

      updateOther({
        agreement: false,
        vaccination: {},
        passport: {}
      })

    }
  }, [])


  useEffect(() => {
    console.log('FORM', other);
    if(other){
      localStorage.setItem('other', JSON.stringify(other))
    }
  }, [other])


  const handleCheckBox = (e) => {

    updateOther({
      ...other,
      [e.target.name]: e.target.checked ? true : false
    });

  };

  const handleChange = async (e) => {

    if(e.target.type === 'file'){

      let file = e.target.files[0];

      file.base64 = await toBase64(e.target.files[0]);

      updateFiles({
        ...files,
        [e.target.name]: file
      });

    } else {


      updateOther({
        ...other,
        [e.target.name]: e.target.value
      });

    }

  };

  const buildForm = () => {


    let student = JSON.parse( localStorage.getItem('student') );
    let previous_school = [ JSON.parse( localStorage.getItem('previous_school') ) ];
    let relatives = JSON.parse( localStorage.getItem('relatives') );
    let medical = JSON.parse( localStorage.getItem('medical') );


    let data = {
       "student":{
          "name": student.name,
          "profile":{
             "birthday": student.birthday,
             "nationality": student.nationality,
             "gender": student.gender,
             "first_language": student.first_language,
             "second_language": student.second_language,
             "previous_school": previous_school
          }
       },
       "relatives":[
          {
             "name": relatives.father_name,
             "surname": "",
             "email": relatives.father_email,
             "phone": relatives.father_phone,
             "nationality": relatives.father_nationality,
             "is_parents": true,
             "parent_role":"father"
          },
          {
             "name": relatives.mother_name,
             "surname": "",
             "email": relatives.mother_email,
             "phone": relatives.mother_phone,
             "nationality": relatives.mother_nationality,
             "is_parents": true,
             "parent_role":"mother"
          },
          {
             "name": relatives.r1_name,
             "surname":"",
             "email": relatives.r1_email,
             "phone": relatives.r1_phone,
             "nationality": relatives.mother_nationality,
             "is_parents":false,
             "parent_role":""
          }
       ],
       "medical":{
          "height": medical.height,
          "weight": medical.weight,
          "special_medication": medical.special_medication,
          "special_medication_comment": medical.special_medication_comment,
          "glass": medical.glass,
          "sport_practice": medical.sport_practice,
          "sport_practice_comment": medical.sport_practice_comment,
          "allergies": medical.allergies,
          "emergency_doctor": medical.emergency_doctor,
          "emergency_phone": medical.emergency_phone,
          "emergency_address": medical.emergency_address,
          "problem": other.problem,
          "problem_previous_school": other.problem_previous_school,
          "excluded_school": medical.excluded_school,
          "infantile_disease": medical.infantile_disease,
          "hospital_id": medical.hospital_id
       },
       "passport": files.passport ? files.passport.base64 : null,
       "vaccination": files.vaccination ? files.vaccination.base64 : null,
       "photo": files.photo ? files.photo.base64 : null,
       "custom_fields":{
        'CUSTOM_CUSTOMER_TRIP_AGREEMENT': other.trip_agreement,
        'CUSTOM_CUSTOMER_RIGHT_TO_IMAGE': other.right_to_image,
        'CUSTOM_CUSTOMER_WEBFORM_NOTES': other.custom_customer_webform_notes
       }

    }

    return data;

  }

  const handleSubmit = async (e) => {

    e.preventDefault()

    const data = await buildForm();


    axios.post(baseUrl+'/webform', data, {
      headers: {
        'Content-Type': 'application/json',
        'company': 1
      }
    })
      .then(response => {
        updateStep(1);
        alert('Thank you');
        window.location.href = "https://sa-saigon.com?utm=subscribe-form&utm_keyword=success";
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Something went wrong, please contact us');
      });

  }

  if(other){

    return(
      <div>
       <form onSubmit={handleSubmit}>
          <h2>Derniers détails / Other informations</h2>

          <h3>Documents</h3>

          <div className="row">
            <div className="col-md-10">
              <div className="other-group mt-4">
                <label for="passport">Passeport</label>
                <div className="custom-file mb-3">
                  <input onChange={handleChange} type="file" className="custom-file-input" id="passport" name="passport" accept="image/*, application/pdf" />
                  <label className="custom-file-label" for="passport">{files.passport ? files.passport.name : "Upload student passport, ID card..."}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10">
              <div className="other-group mt-4">
                <label for="vaccination">Vaccination card</label>
                <div className="custom-file mb-3">
                  <input onChange={handleChange} type="file" className="custom-file-input" id="vaccination" name="vaccination" accept="image/*, application/pdf" />
                  <label className="custom-file-label" for="vaccination">{files.vaccination ? files.vaccination.name : "Upload vaccination card..."}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10">
          <div className="form-group mt-4">
            <label for="weight">Student photo</label>
            <div className="custom-file mb-3">
              <input onChange={handleChange} type="file" className="custom-file-input" id="photo" name="photo" accept="image/*"/>
              <label className="custom-file-label" for="photo">{files.photo ? files.photo.name : "Upload student photo..."}</label>
            </div>
          </div>
          </div>
        </div>


          <h3>Règlement intérieur</h3>


          <div className="other-group">
          <Form.Check
            type="switch"
            id="behavior_problem"
            name="behavior_problem"
            label="L’enfant a t’il déjà eu un problème de développement ou de comportement ?"
            onChange={handleCheckBox}
            checked={other.behavior_problem || false}
          />
          <small className="other-text text-muted">Did the child have any development or behavior problem?</small>
          </div>


          <div className="other-group">
          <Form.Check
            type="switch"
            id="problem"
            name="problem"
            label="L’enfant a t’il eu un problème dans sa précédente école ?"
            onChange={handleCheckBox}
            checked={other.problem || false}
          />
          <small className="other-text text-muted">Did the child have any problem at his/her previous school?</small>
          </div>

          <div className="other-group">
          <Form.Check
            type="switch"
            id="problem_previous_school"
            name="problem_previous_school"
            label="L’enfant a t’il déjà été renvoyé ou suspendu dans une précédente école ?"
            onChange={handleCheckBox}
            checked={other.problem_previous_school || false}

          />
          <small className="other-text text-muted">Has the child been excluded or suspended from any school?</small>
          </div>

          <div className="other-group">
          <Form.Check
            type="switch"
            id="trip_agreement"
            name="trip_agreement"
            label="Autorisation de sortie ? / Trip Agreement?"
            onChange={handleCheckBox}
            checked={other.trip_agreement || false}

          />
          <small className="other-text text-muted">Autorise mon enfant à participer aux sorties scolaires et sportives prévues par les enseignants.</small>
          <small className="other-text text-muted">Agree my child to participate at the trips and sports organized by the teachers.</small>

          </div>

          <div className="other-group">
          <Form.Check
            type="switch"
            id="right_to_image"
            name="right_to_image"
            label="Droit à l'image / Right to image"
            checked={other.right_to_image || false}
            onChange={handleCheckBox}
          />
          <small className="other-text text-muted">J'autorise l’école Saint Ange à publier des photos où se trouverait mon enfant</small>
          <small className="other-text text-muted">I authorize Saint Ange School to publish pictures where my child can be.</small>

          </div>


          <div className="other-group">
          <Form.Check
            type="switch"
            id="agreement"
            name="agreement"
            label="Accord / Agreement"
            checked={other.agreement || false}
            onChange={handleCheckBox}
          />
          <small className="other-text text-muted">Je certifie exacts les renseignements ci-dessus et m’engage à respecter le règlement intérieur et les modalités de l’école Saint Ange, en particulier à signaler 48h à l’avance par écrit à l’enseignant et/ou à la direction de l’école, toute absence de mon enfant.</small>
          <small className="other-text text-muted">I certify exact all the inotheration above and commits me to respect the rules of procedure and the methods of Saint Ange School , in particular to announce 48 hours in advance in writing with the teacher and/or the direction of the school, any absence of my child</small>
          <small className="other-text text-muted">
            J'autorise les représentants de l'école à prendre, en cas d'urgence, les mesures nécessaires à la santé de mon enfant (soins infirmiers, premiers secours, transfert en ambulance, hospitalisation, opération…)
          </small>
          <small className="other-text text-muted">
            J'autorise les représentants de l'école à prendre, en cas d'urgence, les mesures nécessaires à la santé de mon enfant (soins infirmiers, premiers secours, transfert en ambulance, hospitalisation, opération…)
          </small>

          </div>

          <div>
            <textarea
              placeholder="Comments / Commentaires"
              onChange={handleChange}
              className="form-control"
              id="custom_customer_webform_notes"
              name="custom_customer_webform_notes"
              value={other.custom_customer_webform_notes}/>
          </div>

          <div className="my-3">
            <button className="btn btn-secondary mr-1" onClick={() => props.updateStep('dec')}>Back</button>
            <button type="submit" disabled={other.agreement === false} className="btn btn-warning">Submit subscription</button>
          </div>

      </form>

      <ProgressBar value={props.progress || 0} />

    </div>
    );



  }


};

 export default StepOther;
