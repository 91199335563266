import React from 'react';


const ProgressBar = (props) => {

  const width = props.value+"%"
  const value = props.value

  return(

    <div class="my-3 py-3">
    <p>Progress:</p>
    <div class="progress">
      <div class="progress-bar" role="progressbar" style={{width: width}} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    </div>

  );


};

 export default ProgressBar;
